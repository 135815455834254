//
// Tables
//

@import "../core/tables/table";

@media screen and (min-width: 867px) {
    .table-responsive {
        max-height: 800px;
    }

    .row-td {
        font-size: 12px;
    }
    
    .row-td-resume {
        font-size: 11px;
    }
}

@media screen and (max-width: 866px) {
    .table-responsive {
        max-height: 600px;
    }

    .row-td {
        font-size: 11px;
    }
    
    .row-td-resume {
        font-size: 10px;
    }
}

table {
    width: 100%;
}

th {
    position: sticky;
    top: -0.05rem;
    background-color: black;
    color: white;
    font-size: 11px;
}

.tb-striped {
    tr:nth-child(even) {
        background-color: #fafafa;
    }
    tr:nth-child(odd) {
        background-color: #ffffff;
    }
}

.tb-nowrap {
    white-space: nowrap;
}

.tb-border {
    th, td {
        border: 0.05rem solid rgb(216, 215, 215);
    }     
}

.thead-fixed-cell {
    position: sticky;
    left    : 0;
    z-index : 3;
}

.tbody-fixed-cell {
    position        : sticky;
    left            : 0;
    z-index         : 2;
    background-color: #ececec;
}

.row {
    display        : flex;
    justify-content: center;
}