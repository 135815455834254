/* You can add global styles to this file, and also import other style files */
.jexcel tbody tr:nth-child(even) {
    background-color: #EEE9F1 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.modal-header {
    border-bottom: 1px solid #dee2e6;
    padding: 1rem 1rem;
}

.modal-footer {
    border-top: 1px solid #dee2e6;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-backdrop.show {
    background-color: #fff;
    opacity: 0.75;
}

ngb-modal-backdrop {
    z-index: 999 !important;
}

@media screen and (min-width: 768px) {
    .main-content-closed {
        margin-left: 62px !important;
    }

    .admin {
        margin-left: 250px;
    }
}

.color-gray {
    color: rgb(225, 225, 225);
}

/* Chrome, Safari, Edge, Opera - remove arrow dos input number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.fa-coins {
    color: rgb(206, 206, 67)
}

.jspreadsheet-maxheight {
    max-height: 520px;
    min-height: 300px;
}

.fa {
    width: 1rem;
}

.fas {
    width: 1rem;
}

.far {
    width: 1rem;
}

.filter-tilte {
    color: rgb(2, 0, 92);
    margin-top: 0;
    margin-bottom: 0%;
    font-size: smaller;
    margin-left: 0.3rem;
}

.fa-calendar {
    border: 1px solid #c6c7c7;
    border-radius: 5px;
    width: 28px;
    height: 30px;
}

.input-date {
    text-align: center;
    color: rgb(134, 134, 134);
    font-size: small;
    min-width: 130px;
    max-width: 130px;
    height: 32px;
    border: 1px solid #c6c7c7;
    border-radius: 5px;
}

.card-title {
    color: rgb(148, 148, 148);
    font-size: small;
    margin-bottom: 0 !important;
    text-transform: uppercase;
}
