// Use sass math
@use "sass:math";

// Icons
@import "../vendor/nucleo/css/nucleo.css";
@import "../vendor/jsuites.css";
@import "../vendor/@fortawesome/fontawesome-free/css/all.min.css";

// Components
@import "node_modules/ngx-toastr/toastr.css";
@import "jspreadsheet-ce/dist/jspreadsheet.css";
@import "@ng-select/ng-select/themes/default.theme.css";

// Common customizations
@import "common.scss";

// Core
@import "custom/functions";
@import "custom/variables";
@import "custom/mixins";
@import "custom/modal";

// Bootstrap (4.6) components
// @import "bootstrap/scss/bootstrap";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/media";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/print";

// Argon utilities and components
@import "custom/reboot";
@import "custom/utilities";
@import "custom/components";

// Vendor (Plugins)
@import "custom/vendors";

// Angular Differences
@import "angular-differences/angular-differences";
