//
// Chart
//

@import "../core/charts/chart";

.chart-dlp {
    height       : 350px;
    margin-top   : 15px;
    margin-bottom: 18px;
    border       : 1px solid #c7c6c6;
}
